import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    catalogList: []
}

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setCatalogList: (state, action) => {
        state.catalogList = [...action.payload]
    },
    catalogReset: () => {
      return {...initialState}
    }
  }
})

export const { setCatalogList, catalogReset } = catalogSlice.actions

export default catalogSlice.reducer