const settings =  {
    dbAddress: 'https://mobilecash.laimalux.com/Trade_hs/hs/shop/',
    API: "shop/",
    myDomain: "https://laimalux.store/",
    appName: "Laimalux",
    google_reCapcha_site_key: "6LcEkTMmAAAAACugoGBs91L1OWfPBh9sTLarbuI3",
    google_reCapcha_secret_key: "6LcEkTMmAAAAAN58Ehg779SPR4yyim2T9NCucUJK",
    initParentID: "00000000-0000-0000-0000-000000000000",
    deliveryMethods: [
        {dbName: "Самовывоз", uiName: "Самовывоз"},
        {dbName: "ДоКлиента", uiName: "Доставка курьером до клиента"},
        {dbName: "СиламиПеревозчикаПоАдресу", uiName: "Доставка партнером-перевозчиком"},
    ],
    paymentMethods: [
        {dbName: "Наличная", uiName: "Наличными"},
        {dbName: "Безналичная", uiName: "Банковский перевод"},
        {dbName: "ПлатежнаяКарта", uiName: "Оплата платежной картой"},
    ],
    filtersCategories: [
        {name: "Технические Характеристики", opened: false, values:[
            {dbName: "АвтоматическоеОтключениеМин", uiName: "Автоматическое отключение (Мин)", opened: false},
            {dbName: "Ионизация", uiName: "Ионизация", opened: false},
            {dbName: "МощностьВт", uiName: "Мощность (Ватт)", opened: false},
            {dbName: "Использование", uiName: "Возможность использования", opened: false},
            {dbName: "МатериалКорпуса", uiName: "Материал корпуса", opened: false},
            {dbName: "НапряжениеV", uiName: "Напряжение (Вольт)", opened: false},
            {dbName: "ПодачаХолодногоВоздуха", uiName: "Подача Холодного Воздуха", opened: false},
            {dbName: "Покрытие", uiName: "Покрытие", opened: false},
            {dbName: "РазмерПластиныДШСм", uiName: "Размер пластины", opened: false},
            {dbName: "СенсорноеУправление", uiName: "Сенсорное управление", opened: false},
            {dbName: "ТемпературныйРежимC", uiName: "Температугный Режим (C)", opened: false},
            {dbName: "ТипВинта", uiName: "Тип винта", opened: false},
            {dbName: "ШнурМ", uiName: "Длина шнура (М)", opened: false},
            {dbName: "Цвет", uiName: "Цвет", opened: false}
        ]},
        {name: "Характеристики Товаров", opened: false, values: [
            {dbName: "Категория", uiName: "Категория", opened: false},
            {dbName: "Линия", uiName: "Линия товаров", opened: false},
            {dbName: "Назначение", uiName: "Назначение продукта", opened: false},
            {dbName: "Оттенок", uiName: "Оттенок", opened: false},
            {dbName: "Сегмент", uiName: "Сегмент", opened: false},
            {dbName: "СтепеньФиксации", uiName: "Степень Фиксации", opened: false},
            {dbName: "ТипПродукта", uiName: "Тип продукта", opened: false},
            {dbName: "УровеньТона", uiName: "Уровень тона", opened: false},
            {dbName: "УслугаЭффект", uiName: "Услуга эффект", opened: false},
            {dbName: "ДлинаВолосСм", uiName: "Длина волос (см)", opened: false},
            {dbName: "ДлинаПолотнаДюйм", uiName: "Длина полотна (дюйм)", opened: false},
            {dbName: "КоличествоЗубцов", uiName: "Количество Зубцов", opened: false},
            {dbName: "Щетина", uiName: "Щетина"},
        ]},
        {name: "Характеристики Инструментов", opened: false, values: [
            {dbName: "ВидНожниц", uiName: "Вид ножниц", opened: false},
            {dbName: "СрокСлужбыДоЗаточкиПри10СтрижкахДеньГод", uiName: "Срок Службы до заточки (Год) (10 стрижек в день)", opened: false},
            {dbName: "Сталь", uiName: "Сталь", opened: false},
            {dbName: "ТвердостьСталиПоШкалеРоквеллаHRC", uiName: "Твердость Стали (по шкале Роквела HRC)", opened: false},
            {dbName: "ТипСреза", uiName: "Тип среза", opened: false},
            {dbName: "ФормаЛезвия", uiName: "Форма лезвия", opened: false},
            {dbName: "ФормаРучки", uiName: "Форма ручки", opened: false},
        ]},
        {name: "Прочее", opened: false, values: [
            {dbName: "ДиаметрРабочейПоверхностиМм", uiName: "Диаметр рабочей поверхности (мм)", opened: false},
            {dbName: "ТипУпаковки", uiName: "Тип упаковки", opened: false}
        ]},
    ]
};

export default settings;
