import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import IconButton from '@mui/material/IconButton';
import request from '../../_network/request';
import Settings from '../../env/settings';
import urls from '../../_network/urls';
import { setWishList } from '../../features/wishlist/wishlistSlice';


const CustomHeartIcon = (props) => {
    const { product, sx } = props
    const login = useSelector(state => state.profile.login)
    const password = useSelector(state => state.profile.password)
    const wishList = useSelector(state => state.wishlist.wishList)
    const [isWish, setIsWish] = useState(
        wishList.some((item) => item.id === product.id))

    const dispatch = useDispatch();

    const favoritesStateChangeHandler = async () => {
        if (isWish) {
            await request(
                Settings.myDomain + Settings.API + urls.wish_remove, 
                "GET", 
                {productid: product.id},
                undefined,
                {login: login, password: password}
                )
        } else {
            await request(
                Settings.myDomain + Settings.API + urls.wish_add, 
                "GET", 
                {productid: product.id},
                undefined,
                {login: login, password: password}
                )
                
        }
        const wish_list = await request(
            Settings.myDomain + Settings.API + urls.wish_get, 
            "GET",
            {},
            undefined,
            {login: login, password: password}
        )
        dispatch(setWishList(wish_list))
    }

    useEffect(() => {
        setIsWish(wishList.some((item) => item.id === product.id))
    },[wishList, product])

    if (isWish) {
        return (
            <IconButton
                onClick={favoritesStateChangeHandler}
                sx={{...sx}}
            >
                <FavoriteIcon color="primary" />
            </IconButton>
            
        )
    }
    return (
        <IconButton
                onClick={favoritesStateChangeHandler}
                sx={{...sx}}
        >
            <FavoriteBorderIcon color="primary" />
        </IconButton>
        
    )
}

export default CustomHeartIcon;


