import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Settings from '../../env/settings';
import urls from '../../_network/urls';
import request from '../../_network/request';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import ProductCard from '../common/ProductCard';
import { setCartData } from "../../features/cart/cartSlice";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useWindowDimensions from '../common/WindowDimensions';

const FavoritesListContent = (props) => {
    const { width } = useWindowDimensions();
    const wishList = useSelector(state => state.wishlist.wishList);
    const login = useSelector(state => state.profile.login)
    const password = useSelector(state => state.profile.password)
    const [backdropOpen, setBackdropOpen] = useState(false);

    const dispatch = useDispatch();

    const addToCartHandler = async (product) => {
        handleBackdropOpen()
        await request(
            Settings.myDomain + Settings.API + urls.cart_add,
            "GET",
            {   
                itemid: product.id, 
                calculatediscounts: !product.quantity
            },
            undefined,
            {login: login, password: password}
            )
        const cart_data = await request(
            Settings.myDomain + Settings.API + urls.cart_get_list,
            "GET",
            {},
            undefined,
            {login: login, password: password}
        )
        
        dispatch(setCartData(cart_data))
        handleBackdropClose()
    }

    const handleBackdropOpen = () => {
        setBackdropOpen(true);
    };

    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    const calculateSize = () => {
        if (width < 800) {
            return 400
        } else if (width < 1800) {
            return 500
        } else {
            return 800
        }
    }
    
    return (
        <div style={{
            marginBottom: 100, 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center'
        }}>
            <Typography 
                variant="h4"
                color="secondary.light"
                sx={{alignSelf: 'flex-start', flex: 1, mb: 5}}
            >
                Избранное
            </Typography>
            {wishList.length > 0 && <Grid container spacing={3}>
                {wishList.map((item) => {
                    return (
                        <Grid
                            item 
                            key={item.id}  
                            xs={12} 
                            sm={6} 
                            md={4} 
                            lg={2} 
                            sx={{display: 'flex'}}
                        >
                            <ProductCard 
                                item={item} 
                                addToCart={addToCartHandler} 
                            />
                        </Grid>
                    )
                })}
            </Grid>}
            {wishList.length === 0 &&
                <Box sx={{
                    display: 'flex', 
                    flexDirection: 'column',
                    justifyContent: 'center', 
                    alignItems: 'center',
                    backgroundColor: 'customGray.light',
                    borderRadius: '50%',
                    height: calculateSize(),
                    width: calculateSize(),
                    mt: 5,
                    opacity: 0.6
                }}>
                    <FavoriteBorderIcon 
                        color="secondary" 
                        sx={{fontSize: 128}} />
                    <Typography 
                        color="secondary" 
                        sx={{
                            fontSize: 28, 
                            fontWeight: '500'
                        }}
                    >
                        Список избранных пуст
                    </Typography>
                </Box>
            }
            <Backdrop
                sx={{ 
                    color: '#fff', 
                    zIndex: (theme) => theme.zIndex.drawer + 1 
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default FavoritesListContent;
