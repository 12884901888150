const urls = {
    recapcha: "recaptcha/api/siteverify",
    user_profile: "profile/get-profile-data/",
    catalog_list: 'catalog/get-list/',
    catalog_image: 'catalog/get-image/',
    filter_list: 'filter/get-list/',
    product_list: 'product/get-list/',
    product_image: 'product/get-image/',
    cart_add: 'cart/add-item/',
    cart_delete: 'cart/delete-item/',
    cart_get_list: 'cart/get-list/',
    wish_get: 'wish/get-list/',
    wish_remove: 'wish/remove-item/',
    wish_add: 'wish/add-item/',
    order_add: 'order/add-item/',
    order_get_list: 'order/get-list/',
    order_get: 'order/get-item/',
}

export default urls;