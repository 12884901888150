import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NavBar from './NavBar';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import HistoryIcon from '@mui/icons-material/History';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import { setDrawerIndex } from '../../features/webapp/drawerSlice';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const CustomDrawer = (props) => {
  const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
  const wishlist = useSelector(state => state.wishlist.wishList)
  const cart = useSelector(state => state.cart.products)
  const drawerIndex = useSelector(state => state.drawer.drawerIndex)
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDrawerItemClick = (event, index) => {
    dispatch(setDrawerIndex({drawerIndex: index}));
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
        {isAuthenticated && <IconButton
            color="inherit"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <WidgetsOutlinedIcon fontSize="large" />
          </IconButton>}
          <NavBar />
        </Toolbar>
      </AppBar>
      {isAuthenticated && <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{backgroundColor: 'primary.main'}}>
        <IconButton
            onClick={handleDrawerClose}
            edge="start"
            sx={{
              marginRight: 15,
              color: 'customWhite.main',
              ...(!open && { display: 'none' }),
            }}
          >
            <WidgetsOutlinedIcon fontSize="large" />
          </IconButton>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? 
              <ChevronRightIcon sx={{color: 'customWhite.main'}} fontSize="large" /> : 
              <ChevronLeftIcon sx={{color: 'customWhite.main'}} fontSize="large" />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={drawerIndex === 0}
              onClick={(event) => {handleDrawerItemClick(event, 0); navigate('/')}}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  py: 1,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: 'secondary.main'
                }}
              >
                <LibraryBooksIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText 
                primary="Каталог Товаров" 
                primaryTypographyProps={{fontWeight: '600'}} 
                sx={{ 
                  color: 'secondary.main', 
                  fontWeight: 'bold', 
                  opacity: open ? 1 : 0 }} 
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={drawerIndex === 1}
              onClick={(event) => {
                handleDrawerItemClick(event, 1); 
                navigate('/favorites')}}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  py: 1,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: 'secondary.main'
                }}
              >
              <Badge 
                badgeContent={wishlist.length} 
                overlap="circular" 
                color="primary"
              >
                <FavoriteBorderIcon fontSize="large" 
              />
              </Badge>
              </ListItemIcon>
              <ListItemText 
                primary="Избранное" 
                primaryTypographyProps={{fontWeight: '600'}} 
                sx={{ 
                  color: 'secondary.main', 
                  fontWeight: 'bold', 
                  opacity: open ? 1 : 0 }} 
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={drawerIndex === 2}
              onClick={(event) => {handleDrawerItemClick(event, 2); navigate('/cart')}}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  py: 1,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: 'secondary.main'
                }}
              >
                <Badge 
                  badgeContent={cart.length} 
                  overlap="circular" 
                  color="primary"
                >
                  <ShoppingCartIcon fontSize="large" />
                </Badge>
              </ListItemIcon>
              <ListItemText 
                primary="Корзина" 
                primaryTypographyProps={{fontWeight: '600'}} 
                sx={{ 
                  color: 'secondary.main', 
                  fontWeight: 'bold', 
                  opacity: open ? 1 : 0 }} 
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={drawerIndex === 3}
              onClick={(event) => {handleDrawerItemClick(event, 3); navigate('/orders')}}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  py: 1,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: 'secondary.main'
                }}
              >
                <HistoryIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText 
                primary="История Заказов"
                primaryTypographyProps={{fontWeight: '600'}} 
                sx={{ 
                  color: 'secondary.main', 
                  fontWeight: 'bold', 
                  opacity: open ? 1 : 0 }} 
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
          {props.children}
      </Box>
      
    </Box>
  );
}
export default CustomDrawer