import React from 'react';
//import { useSelector } from 'react-redux';
//import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import BrandsSlider from '../common/BrandsSlider';
import useWindowDimensions from '../common/WindowDimensions';
import QuickButton from '../common/QuickButton';
import SearchButton from '../common/SearchButton';
import img1 from '../../../assets/img/start_page_img1.jpg';
import img2 from '../../../assets/img/start_page_img2.jpg';
import img3 from '../../../assets/img/start_page_img3.jpg';
import img4 from '../../../assets/img/start_page_img4.jpg';

const StartPageContent = (props) => {
    //const navigate = useNavigate();
    const { height } = useWindowDimensions();
    
    const CustomGridItem = (url) => {
        return (
            <Grid item xs={12} md={6} lg={3}>
                    <Card sx={{ maxWidth: 700,  }}>
                        <CardMedia
                            component="img"
                            height={height > 1000 ? 1100 : 500}
                            src={url}
                            alt="Catalog Photo"
                        />
                    </Card>
                </Grid>
        )
    }
    
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            position: 'relative'
        }}>
            <Typography 
                variant="h4"
                color="secondary.light"
                sx={{alignSelf: 'flex-start', flex: 1, mb: 5, display: 'inline'}}
            >
                Каталог
            </Typography>
            <Box sx={{position: 'fixed', left: 220}}>
                <SearchButton size="large" color="secondary" />
            </Box>
            <QuickButton />
            <Grid container spacing={2}>
                {CustomGridItem(img1, 1)}
                {CustomGridItem(img2, 2)}
                {CustomGridItem(img3, 3)}
                {CustomGridItem(img4, 4)}
            </Grid>
            
            <Box sx={{position: 'fixed', bottom: 10}}>
                <BrandsSlider />
            </Box>
        </Box>
    )
}

export default StartPageContent;