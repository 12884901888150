import React from 'react';
import Button from '@mui/material/Button';

const SubmitButton = (props) => {
    const {submitBtnText, formSubmitHandler, mainColor, lightColor} = props

    const submitButtonHandler = () => {
        formSubmitHandler();
    }

    return (
        <Button 
                onClick={submitButtonHandler}
                variant="outlined" 
                size="large"
                sx={{
                    alignSelf: 'center',
                    mt: 2,
                    py: 3,
                    borderRadius: 50, 
                    borderColor: mainColor ? mainColor : 'secondary.main',
                    width: '17rem',
                    height: '3rem', 
                    color: 'white', 
                    backgroundColor: mainColor ? mainColor : 'secondary.main',
                    '&:hover': {
                        borderColor: mainColor ? mainColor : 'secondary.main',
                        backgroundColor: lightColor ? lightColor : 'secondary.light',
                        opacity: [0.7, 0.6, 0.5],
                        },
                }}
        >
                {submitBtnText}
        </Button>
    )
};

export default SubmitButton;