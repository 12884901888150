import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Settings from '../../env/settings';
import urls from '../../_network/urls';
import request from '../../_network/request';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useWindowDimensions from '../common/WindowDimensions';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomDialog from '../common/CustomDialog';
import Grid from '@mui/material/Grid';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const OrdersPageContent = (props) => {
    const { width } = useWindowDimensions();
    const login = useSelector(state => state.profile.login)
    const password = useSelector(state => state.profile.password)
    const orders = useSelector(state => state.orders.orders)
    const [orderFilter, setOrderFilter] = useState("");
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [filteredOrders, setFilteredOrders] = useState(orders);
    const [orderDialogVisible, setOrderDialogVisible] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState({});

    const handleBackdropOpen = () => {
        setBackdropOpen(true);
    };

    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    const handleOrderDialogOpen = () => {
        if (selectedOrder) {
            setOrderDialogVisible(true);
        }
    }

    const handleOrderDialogClose = () => {
        setSelectedOrder({})
        setOrderDialogVisible(false)
    }

    const calculateSize = () => {
        if (width < 800) {
            return 400
        } else if (width < 1800) {
            return 500
        } else {
            return 800
        }
    }
    
    const getImageLink = (product_image) => {
        return `${Settings.dbAddress}${urls.product_image}${product_image}`
    }

    const getProductList = () => {
        if (Object.keys(selectedOrder).includes('products')) {
            const prodList = selectedOrder.products.map(product => {
                return (
                    <Grid key={product.id} container spacing={1}>
                        <Grid item xs={12} md={1}>
                            {
                                <img 
                                    height="100"
                                    src={getImageLink(product.image)}
                                    alt="Фото нет"
                                    
                                />
                            }
                        </Grid>
                        {(!product.name1 && !product.name2) && 

                            <Grid item xs={12} md={7}>
                            <Typography color='customGray'>
                                {product.name}
                            </Typography>
                        </Grid>
                        }
                        {product.name1 && 
                        <Grid item xs={12} md={2}>
                            <Typography color='customGray'>
                                {product.name1}
                            </Typography>
                        </Grid>
                        }
                        {product.name2 && 
                            <Grid item xs={12} md={5}>
                                <Typography color='customGray'>
                                    {product.name2}
                                </Typography>
                            </Grid>
                        }
                        <Grid item xs={12} md={2}>
                            <Typography color='customGray'>
                                ({product.quantity} шт.)
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Typography 
                                color='customGray' 
                                fontWeight='bold' 
                                sx={{textAlign: 'end'}}
                            >
                                {product.total}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            })
            return prodList
        }
    }

    const getOrderDetails = async (order_id) => {
        handleBackdropOpen();
        const order_item = await request(
            Settings.myDomain + Settings.API + urls.order_get,
            'GET',
            { orderid: order_id },
            undefined,
            {login: login, password: password}
            )
        setSelectedOrder(order_item);
        handleBackdropClose();
        handleOrderDialogOpen();
    }

    useEffect(() => {
        if (orderFilter) {
            const filtered_list = orders.filter(
                order => 
                    order.number.toLowerCase().includes(orderFilter.toLowerCase()) || 
                    order.date.toLowerCase().includes(orderFilter.toLowerCase()))
            setFilteredOrders(filtered_list)
        } else {
            setFilteredOrders(orders)
        }
    },[orderFilter, orders]);

    return (
        <div style={{
            marginBottom: 100, 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center'
        }}>
            <Typography 
                variant="h4"
                color="secondary.light"
                sx={{alignSelf: 'flex-start', flex: 1, mb: 5}}
            >
                История Заказов
            </Typography>
            
            {orders.length === 0 &&
                <Box sx={{
                    display: 'flex', 
                    flexDirection: 'column',
                    justifyContent: 'center', 
                    alignItems: 'center',
                    backgroundColor: 'customGray.light',
                    borderRadius: '50%',
                    height: calculateSize(),
                    width: calculateSize(),
                    mt: 5,
                    opacity: 0.6
                }}>
                    <ManageSearchIcon 
                        color="secondary" 
                        sx={{fontSize: 128}} />
                    <Typography 
                        color="secondary" 
                        sx={{
                            fontSize: 28, 
                            fontWeight: '500'
                        }}
                    >
                        Список заказов пуст
                    </Typography>
                </Box>
            }
            {orders.length > 0 && <Box
                sx={{
                    mx: 2,
                    p: 1,
                    display: 'flex',
                    flexDirection: {xs: 'column', md: 'row'},
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'customGray.light',
                    width: '90%'
                }}
            >
                <Paper elevation={0} sx={{width: {xs: '80%', md: 300}}}>
                    <TextField
                        variant="outlined"
                        label="Фильтр"
                        size="small"
                        sx={{width: {xs: '100%', md: 300}}}
                        value={orderFilter}
                        onChange={(event) => {
                        setOrderFilter(event.target.value);
                        }}
                    />
                </Paper>
                <Stack direction="row" spacing={2} alignItems='center'>
                    <Typography 
                        color='primary' 
                        fontSize='large'
                    >
                        Количество заказов:
                    </Typography>
                    <Chip label={orders.length} color="primary" sx={{mx: 1}} />
                </Stack>
            </Box>}
            {orders.length > 0 && <TableContainer component={Paper} sx={{ width: '90%' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Номер Заказа</TableCell>
                            <TableCell align="left">Дата Оформления</TableCell>
                            <TableCell align="left">Сумма заказа</TableCell>
                            <TableCell align="left">Оплачено</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {filteredOrders.map((order) => (
                        <TableRow
                            key={order.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': {cursor: 'pointer'} }}
                            onClick={() => getOrderDetails(order.id)}
                        >
                            <TableCell align="left" component="th" scope="row">{order.number}</TableCell>
                            <TableCell align="left">{order.date}</TableCell>
                            <TableCell align="left">{order.sum}</TableCell>
                            <TableCell align="left">{order.percentPayment}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>}
            <CustomDialog
                maxWidth="md"
                open={orderDialogVisible}
                onClose={handleOrderDialogClose}
                dialogTitle={{
                    title: "Детали Заказа", 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'customGray.dark'}}}
            >
                <Box sx={{backgroundColor: 'primary.light', p: 1}}>
                    <Typography color='customWhite.main'>
                        Товары в заказе
                    </Typography>
                </Box>
                <Box sx={{p: 1}}>
                {getProductList()}
                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Typography color='primary' fontWeight='bold'>
                        Итого:
                    </Typography>
                    <Stack direction="row">
                        <Typography color='primary' fontWeight='bold'>
                            {selectedOrder.total}
                        </Typography>
                    </Stack>
                    
                </Box>
                </Box>
            </CustomDialog>
            <Backdrop
                sx={{ 
                    color: '#fff', 
                    zIndex: (theme) => theme.zIndex.drawer + 1 
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default OrdersPageContent;
