import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    filters: [],
    selectedFilters: {}
}

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFiltersData: (state, action) => {
        state.filters = [...action.payload]
    },
    addSelectedFilter: (state, action) => {
      if (state.selectedFilters[action.payload.name]) {
        state.selectedFilters = {
          ...state.selectedFilters,
          ...{
            [action.payload.name]: [
              ...state.selectedFilters[action.payload.name],
              ...[action.payload.value],
            ],
          },
        }
      } else {
        state.selectedFilters = {
          ...state.selectedFilters,
          ...{
            [action.payload.name]: [
              ...[action.payload.value],
            ],
          },
        }
      }
    },
    deleteSelectedFilter: (state, action) => {
      state.selectedFilters = {
        ...state.selectedFilters,
        ...{
          [action.payload.name]: state.selectedFilters[action.payload.name].filter(item => item !== action.payload.value),
        },
      }
    },
    resetSelectedFilters: (state, action) => {
      state.selectedFilters = {}
    },
    filtersReset: () => {
      return {...initialState}
    }
  }
})

export const { 
  setFiltersData, 
  addSelectedFilter, 
  deleteSelectedFilter, 
  filtersReset, 
  resetSelectedFilters 
} = filtersSlice.actions

export default filtersSlice.reducer