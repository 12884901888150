import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    products: [],
    totalDiscount: "",
    textHeader: "",
    deliveryMethod: "Самовывоз",
    paymentMethod: "Наличная",
    totalPrice: ""
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartData: (state, action) => {
        state.products = [...action.payload.list]
        state.totalDiscount = action.payload.discount
        state.textHeader = action.payload.textHeader
        state.totalPrice = action.payload.total
    },
    cartReset: () => {
      return {...initialState}
    }
  }
  
})

export const { setCartData, cartReset } = cartSlice.actions

export default cartSlice.reducer