import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { dataReset } from '../../features/helper';
import logo from '../../../assets/img/LLG_logo_white.png';

const NavBar = (props) => {
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const user = useSelector(state => state.profile.userName)

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logoutHandler = () => {
        dataReset(dispatch)
        navigate('/')
    }

    return (
        <Container maxWidth="xl">
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between'}}
            >
                <a href='/'>
                    <img 
                        style={{ height: 50, marginLeft: -20 }} 
                        src={logo} alt="Logo" 
                    /> 
                </a>
                {isAuthenticated &&  <Toolbar disableGutters>
                    <Button 
                        variant="outlined"  
                        sx={{
                            marginRight: 2,
                            borderColor: 'white', 
                            color: 'white'}}
                    >
                        {user}
                    </Button>  
                    <Tooltip title="Выйти" >
                        <IconButton 
                            onClick={logoutHandler} 
                            sx={{ p: 1, color: "white", marginRight: -3 }}
                        >
                            <Logout fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </Toolbar>}
            </Box>
        </Container>
    );
}

export default NavBar;