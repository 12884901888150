import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FormHelperText from '@mui/material/FormHelperText';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SubmitButton from '../common/SubmitButton';
import ReCAPTCHA from "react-google-recaptcha"
import { 
    setUserData, 
    setUserCredentials, 
    setUserIsAuthenticated 
} from '../../features/profile/profileSlice';
import request from '../../_network/request';
import urls from '../../_network/urls';
import Settings from '../../env/settings';
import { dataFetcher } from '../../features/helper';
import logo from '../../../assets/img/LLG_logo-01.png';
import CustomDialog from '../common/CustomDialog';
import useWindowDimensions from '../common/WindowDimensions';

const LoginForm = (props) => {
    const { width } = useWindowDimensions();
    const [fieldUsername, setFieldUsername] = useState("");
    const [fieldUsernameError, setFieldUsernameError] = useState(false);
    const [fieldPasswordError, setFieldPasswordError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [fieldPassword, setFieldPassword] = useState("");
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [unauthorizedDialogVisible, setUnauthorizedDialogVisible] = useState(false);
    const captchaRef = useRef("")
    const [capchaValidationError, setCapchaValidationError] = useState(false)

    const dispatch = useDispatch();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const passwordInputHandle = (event) => {
        setFieldPasswordError(false)
        setFieldPassword(event.target.value);
    };
    
    const usernameInputHandle = (event) => {
        setFieldUsernameError(false)
        setFieldUsername(event.target.value);
    };

    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    const handleBackdropToggle = () => {
        setBackdropOpen(!backdropOpen);
    };

    const handleUnauthorizedDialogClose = () => {
        setUnauthorizedDialogVisible(false)
    }

    const googleCapchaValidation = async (token) => {
        //console.log(`${urls.recapcha}?secret=${Settings.google_reCapcha_secret_key}&response=${token}`)
        const response = await fetch(
          `${urls.recapcha}?secret=${Settings.google_reCapcha_secret_key}&response=${token}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            }
          }
        )
        
        if (!response.ok) {
          const resErrData = await response.json();
          console.log(resErrData)
         } else {
          const resData = await response.json();
          return resData.success
         }   
      }

    const loginHandler = async (login,password) => {
        try {
            const user_data = await request(
                Settings.API + urls.user_profile, 
                "GET",
                {},
                undefined,
                {login: login, password: password}
                )
            if (user_data.authenticationIsSuccessful) {
                dispatch(setUserCredentials({
                    login: login, 
                    password: password
                }))
                dispatch(setUserIsAuthenticated({
                    isAuthenticated: user_data.authenticationIsSuccessful
                }))
                dispatch(setUserData(user_data))
                
                await dataFetcher(login,password,dispatch)
                setFieldUsername("")
                setFieldPassword("")
            } else {
                setUnauthorizedDialogVisible(true)
            }
        } catch (err) {
            setUnauthorizedDialogVisible(true)
        }
    };

    const submitLoginForm = async () => {
        handleBackdropToggle();
        let formIsValid = true

        if (fieldUsername.length > 0) {
            formIsValid = formIsValid && true
        } else {
            setFieldUsernameError(true)
            formIsValid = false
        }

        if (fieldPassword.length > 0) {
            formIsValid = formIsValid && true
        } else {
            formIsValid = false
            setFieldPasswordError(true)
        }

        if (formIsValid) {
            //const reCapchaResponse = true
            const reCapchaResponse = await googleCapchaValidation(captchaRef.current.getValue())
            if (reCapchaResponse) {
                await loginHandler(fieldUsername, fieldPassword)
            } else {
                formIsValid = false
                setCapchaValidationError(true)
            }
        }
        handleBackdropClose();
    }

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center',
            mt: 5
        }}>
            <img 
                style={{ width: 300 }} 
                src={logo} 
                alt="Logo" 
            />
            <FormControl 
                required 
                error={fieldUsernameError} 
                sx={{ m: 1, width: {xs: 300, md: 500}, mt: 5 }} 
                variant="outlined"
            >
                <InputLabel color="secondary">Логин</InputLabel>
                <OutlinedInput
                    color="secondary"
                    value={fieldUsername}
                    onChange={usernameInputHandle}
                    startAdornment={<InputAdornment position="start">
                        <AccountCircleIcon />
                    </InputAdornment>}
                    label="Логин"
                />
                {fieldUsernameError && 
                    <FormHelperText>
                        Введите ваш логин
                    </FormHelperText>}
            </FormControl>
            <FormControl 
                required 
                error={fieldPasswordError} 
                sx={{ m: 1, width: {xs: 300, md: 500} }} 
                variant="outlined"
            >
                <InputLabel color="secondary">Пароль</InputLabel>
                <OutlinedInput
                    color="secondary"
                    type={showPassword ? 'text' : 'password'}
                    value={fieldPassword}
                    onChange={passwordInputHandle}
                    startAdornment={<InputAdornment position="start">
                        <LockIcon />
                    </InputAdornment>}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                        {showPassword ? 
                            <VisibilityOff /> : 
                            <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                    label="Пароль"
                />
                {fieldPasswordError && 
                    <FormHelperText>
                        Введите правильный пароль
                    </FormHelperText>}
            </FormControl>
            <Box sx={{ mt: 1}}>
                <ReCAPTCHA size={width > 400 ? "normal" : "compact"} style={{width: '100%'}} sitekey={Settings.google_reCapcha_site_key} ref={captchaRef} />
                {capchaValidationError && <FormHelperText style={{color: 'red'}}>Проверка reCAPCHA не пройдена</FormHelperText>}
            </Box>
            <SubmitButton 
                submitBtnText="Войти" 
                formSubmitHandler={submitLoginForm} 
                mainColor="secondary.main"
                lightColor="secondary.light"
            />
            <Backdrop
                sx={{ 
                    color: '#fff', 
                    zIndex: (theme) => theme.zIndex.drawer + 1 
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <CustomDialog
                open={unauthorizedDialogVisible}
                onClose={handleUnauthorizedDialogClose}
                dialogTitle={{
                    title: "Ошибка!", 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "Авторизация неудалась! Попробуйте еще раз.", 
                    sx: {color: 'customGray.dark'}}}
                actionButtons={[{
                    title: "Ок", 
                    onClick: handleUnauthorizedDialogClose, 
                    sx: {color: 'primary.main'}}]}
            />
        </Box>
    )
}

export default LoginForm;