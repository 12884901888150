import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Settings from '../../env/settings';
import urls from '../../_network/urls';
import request from '../../_network/request';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVLink } from "react-csv";
import Box from '@mui/material/Box';
import CustomDialog from './CustomDialog';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Papa from "papaparse";
import { setCartData } from '../../features/cart/cartSlice'

const STAFF_TEMPLATE_HEADERS = [
    {label: 'Articul', key: 'articul'}
]

const ALLOWED_EXTENSIONS = ["csv"];

const QuickButton = (props) => {

    const [importCartProductsDialogVisible, setImportCartProductsDialogVisible] = useState(false);
    const [fileError, setFileError] = useState("");
    const [cartProductsFile, setCartProductsFile] = useState("");
    const [parsedCartProducts, setParsedCartProducts] = useState([]);
    const login = useSelector(state => state.profile.login)
    const password = useSelector(state => state.profile.password)

    const dispatch = useDispatch();

    const csvReport = {
        data: [],
        headers: STAFF_TEMPLATE_HEADERS,
        filename: 'Шаблон_для_списка_в_корзину.csv'
    };

    const handleImportCartProductsDialogVisibleOpen = () => {
        setImportCartProductsDialogVisible(true)
    }

    const handleImportCartProductsDialogVisibleClose = () => {
        setImportCartProductsDialogVisible(false)
    };

    const submitImportCartProductsHandler = async () => {
        setImportCartProductsDialogVisible(false)
        handleParse()
    };

    const handleFileChange = (event) => {
        setFileError("");
         
        if (event.target.files.length) {
            const inputFile = event.target.files[0];
            const fileExtension = inputFile.type.split("/")[1];
            if (!ALLOWED_EXTENSIONS.includes(fileExtension)) {
                setFileError("Ошибка! Выберите CSV файл");
                return;
            }
            setCartProductsFile(inputFile);
        }
    };

    const handleParse = () => {
         
        if (!cartProductsFile) return setFileError("Укажите верный файл");

        const reader = new FileReader();
         
        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true });
            const parsedData = csv.data;
            setParsedCartProducts(parsedData);
        };
        reader.readAsText(cartProductsFile);
    };

    

    useEffect(() => {
        const addToCartHandler = async (item) => {
            await request(
                Settings.myDomain + Settings.API + urls.cart_add,
                "GET",
                {   
                    itemid: item.id, 
                    calculatediscounts: !item.quantity
                },
                undefined,
                {login: login, password: password}
                )
                const cart_data = await request(
                    Settings.myDomain + Settings.API + urls.cart_get_list,
                    "GET",
                    {},
                    undefined,
                    {login: login, password: password}
                )
                dispatch(setCartData(cart_data))
        }

        if (parsedCartProducts.length > 0) {
            (async () => {
                for (var i in parsedCartProducts) {
                    if (parsedCartProducts[i]["Articul"].length > 0) {
                        
                        const product_list = await request(
                            Settings.myDomain + Settings.API + urls.product_list, 
                            "POST", 
                            {}, 
                            {searchText:parsedCartProducts[i]["Articul"]},
                            {login: login, password: password}
                        )
                        
                        if (product_list.length > 0) {
                            addToCartHandler(product_list[0])
                        }
                    }
                }
                })();
        }
    },[dispatch, login, password, parsedCartProducts]);

    return (
        <Box sx={{ 
            height: 0, 
            transform: 'translateZ(0px)', 
            flexGrow: 1 
        }}>
        <SpeedDial
            ariaLabel='cart-products-speed-dial'
            direction='down'
            sx={{
                position: 'absolute', 
                top: -80, 
                right: 0
                }}
            FabProps={{color: 'primary'}}
            icon={<SpeedDialIcon />}
        >
            <SpeedDialAction
                key='importcartproducts'
                icon={<UploadFileIcon />}
                tooltipTitle="Заполнить корзину из файла"
                FabProps={{onClick: handleImportCartProductsDialogVisibleOpen}}
            />
            <SpeedDialAction
                key='downloadtemplate'
                icon={<CSVLink {...csvReport} 
                        style={{color: 'black', opacity: 0.7}}>
                        <DownloadIcon/>
                    </CSVLink>}
                tooltipTitle="Скачать файл шаблона"
            />
        </SpeedDial>
        <CustomDialog
            open={importCartProductsDialogVisible}
            onClose={handleImportCartProductsDialogVisibleClose}
            dialogTitle={{
                title: "Загрузить товары в корзину из файла", 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "Выберите файл для загрузки", 
                sx: {color: 'secondary.main'}}}
            actionButtons={[{
                title: "Загрузить", 
                onClick: submitImportCartProductsHandler, 
                sx: {color: 'primary.main'}}]}
        >
                <TextField 
                    variant='outlined'
                    type='file'
                    error={fileError.length > 0}
                    onChange={handleFileChange}
                    sx={{mt: 3, borderColor: 'customWhite'}}
                />
                {fileError && 
                    <Typography color='error'>
                        {fileError}
                    </Typography>}
        </CustomDialog>
        
    </Box>
    )
}

export default QuickButton;
