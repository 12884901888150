import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import Settings from '../../env/settings';
import urls from '../../_network/urls';
import request from '../../_network/request';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import ProductCard from '../common/ProductCard';
import ProductDetailsDialog from '../common/ProductDetailsDialog';
import { setCartData } from "../../features/cart/cartSlice";
import Typography from '@mui/material/Typography';
import useWindowDimensions from '../common/WindowDimensions';
import Box from '@mui/material/Box';

const FilteredProductListContent = (props) => {
    const { width } = useWindowDimensions();
    const login = useSelector(state => state.profile.login)
    const password = useSelector(state => state.profile.password)
    const [backdropOpen, setBackdropOpen] = useState(true);
    const [productList, setProductList] = useState([]);

    const [productDetailDialogVisible, setProductDetailDialogVisible] = useState(false)
    const [currentItem, setCurrentItem] = useState({});

    const dispatch = useDispatch();
    const location = useLocation();

    let filters = location.state.filters;

    const addToCartHandler = async (product) => {
        handleBackdropOpen()
        await request(
            Settings.myDomain + Settings.API + urls.cart_add,
            "GET",
            {   
                itemid: product.id, 
                calculatediscounts: !product.quantity
            },
            undefined,
            {login: login, password: password}
            )
        const cart_data = await request(
            Settings.myDomain + Settings.API + urls.cart_get_list,
            "GET",
            {},
            undefined,
            {login: login, password: password}
        )
        
        dispatch(setCartData(cart_data))
        handleBackdropClose()
    }

    const handleProductDetailDialogClose = () => {
        setProductDetailDialogVisible(false)
    }

    const handleProductDetailDialogOpen = (item) => {
        setCurrentItem(item)
        setProductDetailDialogVisible(true)
    }

    const handleBackdropOpen = () => {
        setBackdropOpen(true);
    };

    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    const calculateSize = () => {
        if (width < 800) {
            return 400
        } else if (width < 1800) {
            return 500
        } else {
            return 800
        }
    }

    useEffect(() => {
        
        (async () => {
            let product_list = []
            if (filters) {
                product_list = await request(
                    Settings.myDomain + Settings.API + urls.product_list, 
                    "POST", 
                    {}, 
                    {...filters},
                    {login: login, password: password}
                )
            }
            setProductList(product_list)
        })();
        handleBackdropClose();
    },[filters, login, password]);

    
    return (
        <div style={{marginBottom: 100}}>
            <Typography 
                variant="h4"
                color="secondary.light"
                sx={{alignSelf: 'flex-start', flex: 1, mb: 5}}
            >
                Найденные Товары
            </Typography>
            {productList.length === 0 &&
                <Box sx={{
                    display: 'flex', 
                    flexDirection: 'column',
                    justifyContent: 'center', 
                    alignItems: 'center',
                    backgroundColor: 'customGray.light',
                    borderRadius: '50%',
                    height: calculateSize(),
                    width: calculateSize(),
                    mt: '5%',
                    opacity: 0.6,
                    ml: '50%',
                    transform: `translateX(-${calculateSize() /2 }px)`
                }}>
                    <Typography 
                        color="secondary" 
                        sx={{
                            fontSize: 28, 
                            fontWeight: '500'
                        }}
                    >
                        Найдено 0 товаров
                    </Typography>
                </Box>
            }
            <Grid container spacing={3}>
                {productList.map((item) => {
                    return (
                        <Grid
                            item 
                            key={item.id}  
                            xs={12} 
                            sm={6} 
                            md={4} 
                            lg={2} 
                            sx={{display: 'flex'}}
                        >
                            <ProductCard 
                                item={item} 
                                onClick={handleProductDetailDialogOpen} 
                                addToCart={addToCartHandler} 
                            />
                        </Grid>
                    )
                })}
            </Grid>
            <ProductDetailsDialog 
                item={currentItem} 
                open={productDetailDialogVisible} 
                onClose={handleProductDetailDialogClose} 
                addToCart={addToCartHandler}
            />
            <Backdrop
                sx={{ 
                    color: '#fff', 
                    zIndex: (theme) => theme.zIndex.drawer + 1 
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default FilteredProductListContent;
