import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import BrandsSlider from '../common/BrandsSlider';
import Settings from '../../env/settings';
import urls from '../../_network/urls';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import CustomBreadCrumbs from '../common/CustomBreadCrumbs';
import CategoryListItem from '../common/CategoryListItem';
import useWindowDimensions from '../common/WindowDimensions';

const CatalogListContent = (props) => {
    const location = useLocation();
    const { width } = useWindowDimensions();
    const { parent_id } = location.state
    const catalogList = useSelector(state => state.catalog.catalogList)
    const [backdropOpen, setBackdropOpen] = useState(true);
    const [brand, setBrand] = useState(catalogList.filter(
        item => item.id === parent_id)[0])
    const [breadCrumbList, setBreadCrumbList] = useState([]);
    
    const [categoryList, setCategoryList] = useState([]);

    //const navigate = useNavigate();
    const [imageUrl, setImageUrl] = useState(`${Settings.dbAddress}${urls.catalog_image}${parent_id}`)

    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    useEffect(() => {
        const category_list = catalogList.filter(
            item => item.parentId === parent_id
        )
        setCategoryList(category_list)
        handleBackdropClose();
        setBrand(catalogList.filter(
            item => item.id === parent_id
        )[0])
        setImageUrl(`${Settings.dbAddress}${urls.catalog_image}${parent_id}`)
    },[parent_id, catalogList]);

    useEffect(() => {
        let p_id = parent_id
        let b_list = []
        const filter_func = (item_list,p_id) => {
            const it = item_list.filter(item => item.id === p_id)[0]
            return it
        }
        while (p_id !== Settings.initParentID) {
            const it = filter_func(catalogList,p_id)
            p_id = it.parentId
            b_list.unshift(it)
        }
        setBreadCrumbList(b_list.slice(0, -1))
    },[parent_id, catalogList]);
    
    return (
        <Box sx={{
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center'
        }}>
            <CustomBreadCrumbs 
                breadCrumbList={breadCrumbList} 
                brand={brand} 
            />
            <Box 
                sx={{
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'space-between' 
                }}
            >
                <Stack spacing={2} sx={{mb: 20}}>
                    {categoryList.map((item) => {
                        return (
                            <CategoryListItem key={item.id} item={item}/>
                        )
                    })}
                </Stack>
                <Box 
                    sx={{
                        width: {xs: '45%', md: '65%'}, 
                        overflow: 'hidden'
                    }}
                >
                    <img 
                        style={{
                            width: width > 1800 ? 1750 : 1000,
                            position: 'absolute', 
                            top: width > 1800 ? -1400 : -800,
                        }}
                        src={imageUrl}
                        alt="ImageBack"
                    />
                </Box>
            </Box>
            <Box 
                sx={{
                    position: 'fixed', 
                    bottom: 10,
                }}
            >
                <BrandsSlider 
                    activeTab={breadCrumbList.length > 0 ? 
                        breadCrumbList[0].id : 
                        brand.id} 
                />
            </Box>
            <Backdrop
                sx={{ 
                    color: '#fff', 
                    zIndex: (theme) => theme.zIndex.drawer + 1 
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default CatalogListContent;