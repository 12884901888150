import request from '../_network/request';
import urls from '../_network/urls';
import Settings from '../env/settings';
import { setCatalogList, catalogReset } from '../features/catalog/catalogSlice';
import { setCartData, cartReset } from '../features/cart/cartSlice';
import { setWishList, wishlistReset } from '../features/wishlist/wishlistSlice';
import { setOrdersData, ordersReset } from '../features/orders/ordersSlice';
import { setFiltersData, filtersReset } from '../features/filters/filtersSlice';
import { profileReset } from './profile/profileSlice';

export const dataFetcher = async (login, password, dispatch) => {

        const catalog = await request(
            Settings.API + urls.catalog_list, 
            "GET",
            {},
            undefined,
            {login: login, password: password}
        )
        dispatch(setCatalogList(catalog))

        const cart = await request(
            Settings.API + urls.cart_get_list, 
            "GET",
            {},
            undefined,
            {login: login, password: password}
        )
        dispatch(setCartData(cart))

        const wish_list = await request(
            Settings.API + urls.wish_get, 
            "GET",
            {},
            undefined,
            {login: login, password: password}
        )
        dispatch(setWishList(wish_list))

        const orders_list = await request(
            Settings.API + urls.order_get_list, 
            "GET",
            {},
            undefined,
            {login: login, password: password}
        )
        dispatch(setOrdersData(orders_list))

        const fiters_list = await request(
            Settings.API + urls.filter_list, 
            "GET",
            {},
            undefined,
            {login: login, password: password}
        )
        dispatch(setFiltersData(fiters_list))
}

export const dataReset = async (dispatch) => {
    dispatch(catalogReset());
    dispatch(cartReset());
    dispatch(wishlistReset());
    dispatch(filtersReset());
    dispatch(ordersReset());
    dispatch(profileReset());
}