import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    orders: []
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrdersData: (state, action) => {
        state.orders = [...action.payload.list]
    },
    ordersReset: () => {
      return {...initialState}
    }
  }
})

export const { setOrdersData, ordersReset } = ordersSlice.actions

export default ordersSlice.reducer