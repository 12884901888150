import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Settings from '../../env/settings';
import urls from '../../_network/urls';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CustomHeartIcon from './CustomHeartIcon';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const ProductDetailsDialog = (props) => {
    const { item, open, onClose, addToCart } = props
    const [currentIndex, setCurrentIndex] = useState(0)
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [currentTab, setCurrentTab] = useState('one');

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue)
    }

    const handleDialogClose = () => {
        setCurrentIndex(0)
        onClose();
    }

    const onAddToCart = async () => {
        handleBackdropOpen()
        await addToCart(item)
        handleBackdropClose()
    }

    const scrollFront = () => {
        setCurrentIndex(currentIndex + 1)
    };

    const scrollBack = () => {
        setCurrentIndex(currentIndex - 1)
    };

    const handleBackdropOpen = () => {
        setBackdropOpen(true);
    };

    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    const getImageLink = (product_image) => {
        return `${Settings.dbAddress}${urls.product_image}${product_image}`
    }

    const DetailsItem = (props) => {
        const { text, specList, label } = props

        return (
            <Box sx={{p: 3, minHeight: 165}}>
                {text && 
                    <Typography>
                        {text}
                    </Typography>}
                {specList && specList.length > 0 &&
                    <Box>
                    {specList.map((item, index) => {
                        return (
                            <Box key={item.key} 
                                sx={{
                                    borderBottom: specList.length === index + 1 ? 0 : 1,
                                    borderColor: 'customGray.main',
                                    p: 2
                                }}
                            >
                                <Typography>
                                    {item.key}
                                </Typography>
                                <Typography >
                                    {item.value}
                                </Typography>
                            </Box>
                        )
                        }
                    )}
                    </Box>
                }
                {text !== undefined && text.length === 0 && 
                    <Typography>
                        {label} отсутствует
                    </Typography>
                }
                {specList !== undefined && specList.length === 0 &&
                    <Typography>
                        {label} отсутствуют
                    </Typography>
                }
            </Box>
        )
    }

    return (
        <Dialog 
            open={open} 
            TransitionComponent={Transition}
            onClose={handleDialogClose} 
            scroll='paper'
            maxWidth={"lg"}
            fullWidth={true}
        >
        <DialogTitle sx={{color: 'primary.light', fontSize: 22, fontWeight: '600'}}>
            Детали Товара
            <IconButton
                onClick={handleDialogClose}
                sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                <Grid
                    item   
                    xs={12}  
                    lg={6} 
                    sx={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}
                >
                    {Object.keys(item).includes("images") && item.images.length > 1 ? 
                        <Box 
                            sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <img 
                                height="300"
                                
                                src={getImageLink(item.images[currentIndex])} 
                                alt="Фото отсутствует"
                            />
                            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                <IconButton
                                    onClick={scrollBack}
                                    disabled={currentIndex === 0}
                                >
                                    <ArrowBackIosIcon fontSize="small" />
                                </IconButton>
                                {item.images.map((img,index) => 
                                    <IconButton
                                        onClick={() => setCurrentIndex(index)}
                                    >
                                    <CircleIcon key={index}
                                        color={index === currentIndex ? 'primary' : 'customGray'}
                                        fontSize="small"
                                    />
                                </IconButton>
                                )}
                                <IconButton
                                    onClick={scrollFront}
                                    disabled={currentIndex === item.images.length - 1}
                                >
                                    <ArrowForwardIosIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </Box>
                        :
                        <img 
                            height="300"
                            src={getImageLink(item.image)}
                            alt="Фото отсутствует"
                        />
                    }
                    <CustomHeartIcon product={item} />
                </Grid>
                <Grid
                    item   
                    xs={12}  
                    lg={6} 
                    sx={{display: 'flex'}}
                >
                    <Box>
                    <Typography 
                        sx={{
                            fontSize: 20, 
                            fontWeight: '600', 
                            color: 'primary.main', 
                            m: 4
                        }}
                    >
                        {item.name1}
                    </Typography>
                    <Typography 
                        variant="h6" 
                        color="text.secondary"
                        sx={{mx: 4}}
                    >
                        {item.name2}
                    </Typography>
                    <Typography 
                        sx={{
                            fontSize: 20, 
                            m: 4,
                            fontWeight: '600', 
                            color: 'primary.light'
                        }}
                    >
                        {item.price}
                    </Typography>
                    </Box>
                    </Grid>
                </Grid>
                <Box sx={{m: 4}}>
                    <Tabs
                        value={currentTab}
                        onChange={handleTabChange}
                        textColor="primary"
                        indicatorColor="primary"
                    >
                        <Tab value="one" label="Описание" />
                        <Tab value="two" label="Состав" />
                        <Tab value="three" label="Характеристики" />
                    </Tabs>
                    {currentTab === "one" && <DetailsItem label="Описание" text={item.description} />}
                    {currentTab === "two" && <DetailsItem label="Состав" text={item.content} />}
                    {currentTab === "three" && <DetailsItem label="Характеристики" specList={item.specifications} />}
                </Box>
            </DialogContent>
        <DialogActions>
            <Button sx={{m: 3}}
                size="large" 
                variant='contained'
                onClick={onAddToCart}
            >
                В Корзину
            </Button>
        </DialogActions>
        <Backdrop
                sx={{ 
                    color: '#fff', 
                    zIndex: (theme) => theme.zIndex.drawer + 1 
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
    </Dialog>
    )
}

export default ProductDetailsDialog;