import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from 'react-redux';
import { store, persistor } from './app/store';
import { ThemeProvider } from "@mui/material";
import Theme from './themes/Theme';
import { PersistGate } from 'redux-persist/integration/react';
import StartPage from './app/pages/StartPage';
import FavoritesPage from './app/pages/FavoritesPage';
import CartPage from './app/pages/CartPage';
import OrdersPage from './app/pages/OrdersPage';
import CatalogListPage from './app/pages/CatalogListPage';
import ProductListPage from './app/pages/ProductListPage';
import FilteredProductsPage from './app/pages/FilteredProductsPage';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={Theme}>
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<StartPage />} />
              <Route exact path="/catalog" element={<CatalogListPage />} />
              <Route exact path="/products" element={<ProductListPage />} />
              <Route exact path="/favorites" element={<FavoritesPage />} />
              <Route exact path="/cart" element={<CartPage />} />
              <Route exact path="/orders" element={<OrdersPage />} />
              <Route exect path="/filtered-products" element={<FilteredProductsPage />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
