import React from 'react';
import Typography from '@mui/material/Typography';
import Settings from '../../env/settings';
import urls from '../../_network/urls';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CustomHeartIcon from './CustomHeartIcon';

const ProductCard = (props) => {
    const { item, onClick, addToCart } = props

    const getImageLink = (product_image) => {
        return `${Settings.dbAddress}${urls.product_image}${product_image}`
    }

    const handleOnClick = () => {
        if (onClick) {
            onClick(item)
        }
    }

    const onAddToCart = async () => {
        await addToCart(item)
    }
    
    return (
        <Card 
            sx={{ 
                width: 350,
                position: 'relative',
                '&:hover': {
                    cursor: onClick ? 'pointer' : ''
                } 
            }}
        >
        <CustomHeartIcon product={item} sx={{position: 'absolute', top: 5, right: 0}} />
            <CardMedia
                sx={{ 
                    height: 150, 
                    display: 'flex', 
                    justifyContent: 'center', 
                    mt: 2
                }}
                title={item.name}
                onClick={handleOnClick}
            >
                <img 
                    height="150"
                    src={getImageLink(item.image)}
                    alt="Фото отсутствует"
                />
            </CardMedia>
            <CardContent sx={{ height: 200}} onClick={handleOnClick}>
                <Typography 
                    sx={{
                        fontSize: 16, 
                        fontWeight: '600', 
                        color: 'primary.main', 
                        my: 2
                    }}
                >
                    {item.name1.length > 60 ? `${item.name1.slice(0,57)}...` : item.name1}
                </Typography>
                <Typography 
                    variant="body2" 
                    color="text.secondary"
                >
                    {item.name2.length > 80 ? `${item.name2.slice(0,77)}...` : item.name2}
                </Typography>
            </CardContent>
            <CardActions 
                sx={{
                    justifyContent: 'space-between', 
                    m: 3
                }}
            >
                <Typography 
                    sx={{
                        fontSize: 14, 
                        fontWeight: '600', 
                        color: 'primary.light'
                    }}
                >
                    {item.price}
                </Typography>
                <Button 
                    size="small" 
                    variant='contained'
                    onClick={onAddToCart}
                    sx={{fontSize: 11}}
                >
                    В Корзину
                </Button>
            </CardActions>
        </Card>     
    )
}

export default ProductCard;