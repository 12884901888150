import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
    palette: {
      primary: {
        main: "#9E0045",
        dark: "#66001D",
        light: "#EE5F86"
      },
      secondary: {
        main: "#9F6588",
        light: '#C9A0B7',
        dark: '#53424C'
      },
      customGreen: {
        main: "#00977D",
        light: '#00C9A8',
        dark: '#00624C'
      },
      customGray: {
        main: '#b8b8b8',
        dark: '#636161',
        light: '#ccc'
      },
      customInfo: {
        main: '#25b09b'
      },
      customWhite: {
        main: '#fff'
      }
    },
  });
  
  export default Theme;