import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import BrandsSlider from '../common/BrandsSlider';
import Settings from '../../env/settings';
import urls from '../../_network/urls';
import request from '../../_network/request';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import CustomBreadCrumbs from '../common/CustomBreadCrumbs';
import ProductCard from '../common/ProductCard';
import ProductDetailsDialog from '../common/ProductDetailsDialog';
import { setCartData } from "../../features/cart/cartSlice";

const ProductListContent = (props) => {
    //const { parent_id } = useParams();
    const location = useLocation();
    const { parent_id } = location.state
    const catalogList = useSelector(state => state.catalog.catalogList)
    const login = useSelector(state => state.profile.login)
    const password = useSelector(state => state.profile.password)
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [brand, setBrand] = useState(catalogList.filter(
        item => item.id === parent_id)[0])
    const [breadCrumbList, setBreadCrumbList] = useState([]);
    const [productList, setProductList] = useState([]);

    const [productDetailDialogVisible, setProductDetailDialogVisible] = useState(false)
    const [currentItem, setCurrentItem] = useState({});

    const dispatch = useDispatch();

    const addToCartHandler = async (product) => {
        handleBackdropOpen()
        await request(
            Settings.myDomain + Settings.API + urls.cart_add,
            "GET",
            {   
                itemid: product.id, 
                calculatediscounts: !product.quantity
            },
            undefined,
            {login: login, password: password}
            )
        const cart_data = await request(
            Settings.myDomain + Settings.API + urls.cart_get_list,
            "GET",
            {},
            undefined,
            {login: login, password: password}
        )
        
        dispatch(setCartData(cart_data))
        
        const product_list = await request(
            Settings.myDomain + Settings.API + urls.product_list, 
            "POST", 
            {}, 
            {parentId: parent_id},
            {login: login, password: password}
        )
        setProductList(product_list)
        handleBackdropClose()
    }

    const handleProductDetailDialogClose = () => {
        setProductDetailDialogVisible(false)
    }

    const handleProductDetailDialogOpen = (item) => {
        setCurrentItem(item)
        setProductDetailDialogVisible(true)
    }

    //const navigate = useNavigate();

    const handleBackdropOpen = () => {
        setBackdropOpen(true);
    };

    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    useEffect(() => {
        setBrand(catalogList.filter(
            item => item.id === parent_id
        )[0])
    },[parent_id, catalogList]);

    useEffect(() => {
        (async () => {
            handleBackdropOpen();
            const product_list = await request(
                Settings.myDomain + Settings.API + urls.product_list, 
                "POST", 
                {}, 
                {parentId: parent_id},
                {login: login, password: password}
            )
            setProductList(product_list)
            handleBackdropClose();
        })();
    },[parent_id, login, password]);

    useEffect(() => {
        let p_id = parent_id
        let b_list = []
        const filter_func = (item_list) => {
            const it = item_list.filter(item => item.id === p_id)[0]
            return it
        }
        while (p_id !== Settings.initParentID) {
            const it = filter_func(catalogList)
            p_id = it.parentId
            b_list.unshift(it)
        }
        setBreadCrumbList(b_list.slice(0, -1))
    },[parent_id, catalogList]);

    
    return (
        <div style={{marginBottom: 100}}>
            <CustomBreadCrumbs 
                breadCrumbList={breadCrumbList} 
                brand={brand} 
            />
            <Grid container spacing={3}>
                {productList.map((item) => {
                    return (
                        <Grid
                            item 
                            key={item.id}  
                            xs={12} 
                            sm={6} 
                            md={4} 
                            lg={2} 
                            sx={{display: 'flex'}}
                        >
                            <ProductCard item={item} onClick={handleProductDetailDialogOpen} addToCart={addToCartHandler} />
                        </Grid>
                    )
                })}
            </Grid>
            <Box sx={{position: 'fixed', bottom: 10}}>
                <BrandsSlider 
                    activeTab={breadCrumbList.length > 0 ? 
                        breadCrumbList[0].id : 
                        brand.id} 
                />
            </Box>
            <ProductDetailsDialog 
                item={currentItem} 
                open={productDetailDialogVisible} 
                onClose={handleProductDetailDialogClose} 
                addToCart={addToCartHandler}
            />
            <Backdrop
                sx={{ 
                    color: '#fff', 
                    zIndex: (theme) => theme.zIndex.drawer + 1 
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default ProductListContent;
