import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Settings from '../../env/settings';
import Stack from '@mui/material/Stack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import useWindowDimensions from './WindowDimensions';

const BrandsSlider = (props) => {
    const { width } = useWindowDimensions();
    const { activeTab } = props
    const catalogList = useSelector(state => state.catalog.catalogList)
    const [brandList, setBrandList] = useState([]);
    const ref = useRef(null);
    
    const SCROLL_OFFSET = width > 1800 ? 320 : 220
    const ITEM_WIDTH = width > 1800 ? 300 : 200

    const navigate = useNavigate();

    const scrollFront = () => {
        ref.current.scrollLeft -= SCROLL_OFFSET;
    };

    const scrollBack = () => {
        ref.current.scrollLeft += SCROLL_OFFSET;
    };

    const calculateWidth = () => {
        let calculated_width = width > 1800 ? width - 210 : width - 310
        const items_num = brandList.length
        if (items_num * ITEM_WIDTH < calculated_width) {
            calculated_width = width > 1800 ? items_num * ITEM_WIDTH + 210 : items_num * ITEM_WIDTH + 310
        }
        return calculated_width
    }

    useEffect(() => {
        const brand_list = catalogList.filter(
            item => item.parentId === Settings.initParentID
        )

        setBrandList(brand_list)
    },[catalogList]);
    
    return (
        <Box sx={{
            overflow: 'hidden',  
            height: width > 1800 ? 200 : 150,
            width: {
                xs: 320, 
                sm: 540,
                md: 750, 
                lg: calculateWidth()
            }
        }}
    >
    <Stack direction="row" spacing={1} alignItems='center' sx={{width: '100%'}}>
        <Box>
            <IconButton
                onClick={scrollFront}
            >
                <ArrowBackIosIcon/>
            </IconButton>
        </Box>
        <Stack 
            direction="row" 
            spacing={2} 
            sx={{
                overflowX: 'scroll', 
                py: 7, 
                px: 2,
                width: {
                    xs: 220, 
                    sm: 440,
                    md: 650, 
                    lg: width > 1300 ? '100%' : 880
                }
            }} 
            ref={ref}
        >
            {brandList.map((item, index) => (
                <Paper elevation={6}
                    onClick={() => navigate("/catalog", {state: {parent_id: item.id}})}
                    key={item.id} 
                    sx={{ 
                        backgroundColor: activeTab === item.id ? 
                        'primary.main' : 
                        'primary.light',
                        borderRadius: 2,
                        width: ITEM_WIDTH ,
                        display: 'flex',
                        justifyContent: 'center',
                        opacity: 0.9
                    }}
                >
                    <Button
                        variant="text"
                        color="customWhite"
                        sx={{
                            fontSize: width > 1800 ? 22 : 18,
                            borderRadius: 2,
                            py: width > 1800 ? 4 : 2,
                            width: ITEM_WIDTH,
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                                opacity: [0.9, 0.8, 0.7],
                            },
                        }}
                    >
                        {item.name}
                    </Button>
                </Paper>
            ))}
            </Stack>
            <Box>
                <IconButton
                    onClick={scrollBack}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
        </Stack>
    </Box>
    )
}

export default BrandsSlider;