const request = async (url, method, headers, data, login_data) => {
    
    const options = {
        method,
        headers: {
        "Content-Type": "application/json",
        ...headers,
        credentials: "include",
        login: login_data.login,
        password: login_data.password
        }
    }

  if (method === "POST") {
    options.body = JSON.stringify(data)
  }
  const response = await fetch(url, options)
  if (response.ok) {
    return await response.json()
  }

  //console.log("error", url)
  //console.log("Response status:", response.status)

  return { error: true }
}

export default request;