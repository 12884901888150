import React from 'react';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import SearchButton from './SearchButton';

const CustomBreadCrumbs = (props) => {
    const { breadCrumbList, brand } = props
    const navigate = useNavigate();

    return (
        <div>
            <Breadcrumbs sx={{mb: 5}}>
                {breadCrumbList.map((item) => {
                    return (
                        <Link 
                            key={item.id} 
                            underline="hover" 
                            color="inherit" 
                            sx={{
                                '&:hover': {
                                    borderColor: 'secondary.main',
                                    opacity: [0.9, 0.8, 0.7],
                                    cursor: 'pointer'
                                }
                            }}
                            onClick={() => navigate('/catalog', {state: {parent_id: item.id}})}
                        >
                            {item.name}
                        </Link>
                    )
                    })}
                <Typography color="secondary">{brand.name}</Typography>
                <SearchButton />
            </Breadcrumbs>
            
        </div>
    )
}

export default CustomBreadCrumbs;