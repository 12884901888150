import React from 'react';
import { useSelector } from 'react-redux';
import CustomDrawer from '../components/common/CustomDrawer';
import LoginForm from '../components/forms/loginForm';
import FilteredProductListContent from '../components/pages/FilteredProductList';

const ProductList = (props) => {
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    
    return (
        <div>
            <CustomDrawer>
                {!isAuthenticated && <LoginForm />}
                {isAuthenticated && <FilteredProductListContent />}
            </CustomDrawer>
        </div>
    )
}

export default ProductList;