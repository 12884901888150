import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userName: '',
    isAuthenticated: false,
    login: '',
    password: ''
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUserData: (state, action) => {
        state.userName = action.payload.name
    },
    setUserCredentials: (state, action) => {
      state.login = action.payload.login
      state.password = action.payload.password
  },
    setUserIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated
  },
    profileReset: () => {
      return {...initialState}
    }
  }
})

export const { setUserData, profileReset, setUserCredentials, setUserIsAuthenticated } = profileSlice.actions

export default profileSlice.reducer