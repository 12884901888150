import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    drawerIndex: 0
}

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setDrawerIndex: (state, action) => {
        state.drawerIndex = action.payload.drawerIndex
    },
    drawerIndexReset: () => {
      return {...initialState}
    }
  }
})

export const { setDrawerIndex, drawerIndexReset } = drawerSlice.actions

export default drawerSlice.reducer