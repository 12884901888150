import React, { useState } from 'react';
//import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
//import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import CustomDialog from './CustomDialog';
//import Settings from '../../env/settings';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
//import Stack from '@mui/material/Stack';
//import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
//import ArrowRightIcon from '@mui/icons-material/ArrowRight';
//import CheckBoxIcon from '@mui/icons-material/CheckBox';
//import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Button from '@mui/material/Button';
//import ReplayIcon from '@mui/icons-material/Replay';
//import { 
//    addSelectedFilter, 
//    deleteSelectedFilter, 
//    resetSelectedFilters 
//} from '../../features/filters/filtersSlice';


const SearchButton = (props) => {
    const {color, size} = props
    const [searchDialogVisible, setSearchDialogVisible] = useState(false);
    //const [filtersCategories, setFiltersCategories] = useState([...Settings.filtersCategories])
    //const filtersList = useSelector(state => state.filters.filters)
    //const selectedFilters = useSelector(state => state.filters.selectedFilters)
    const [nameFilter, setNameFilter] = useState("");

    //const dispatch = useDispatch()
    const navigate = useNavigate();

    //const handleSelectedFiltersReset = () => {
    //    dispatch(resetSelectedFilters())
    //}

    const nameFilterInputHandle = (event) => {
        setNameFilter(event.target.value);
    }

    const nameSearchHandler = () => {
        if (nameFilter) {
            navigate(
                "/filtered-products", {
                state: {
                    filters: {searchText: nameFilter},
                }
            })
        }
    }

    //const filterApplyHandler = () => {
    //    navigate(
    //        "/filtered-products", 
    //        {state: {filters: {...selectedFilters}}})
    //}

    //const handleFilterDropDown = (parent,filter) => {
    //    const updated_list = filtersCategories.map((category) => {
    //        if (category.name === parent) {
    //            for (var i in category.values) {
    //                if (category.values[i].dbName === filter) {
    //                    category.values[i].opened = !category.values[i].opened
    //                }
    //            }
    //        }
    //        return category
    //    })
    //    setFiltersCategories(updated_list)
    //}

    //const DetailValueItem = (props) => {
    //    const { parent, title, valueList } = props
    //    return (
    //        <Box sx={{m: 1}}>
    //            <Stack direction="row" alignItems="center"
    //                onClick={() => handleFilterDropDown(parent,title.dbName)}
    //            >
    //                <Box sx={{
    //                    borderBottom: 1, 
    //                    borderColor: "secondary.main", 
    //                    p: 1, 
    //                    display: 'flex',
    //                    flexDirection: 'row',
    //                    alignItems: 'center',
    //                    width: '100%',
    //                    m: 1
    //                }}>
    //                    <Typography color="secondary.main">
    //                        {title.uiName}
    //                        
    //                    </Typography>
    //                    {!title.opened ? 
    //                        <ArrowRightIcon color="secondary" /> :
    //                        <ArrowDropDownIcon color="secondary" />
    //                        }
    //                </Box>
    //            </Stack>
    //            {title.opened && <Box>
    //            {valueList.map((value, index) => {
    //                const valueIsUsed = selectedFilters[title.dbName] ? 
    //                    selectedFilters[title.dbName].includes(value) : 
    //                    false
    //                const onClick = () => {
    //                    if (valueIsUsed) {
    //                        dispatch(deleteSelectedFilter({
    //                            name: title.dbName, 
    //                            value: value
    //                        }))
    //                    } else {
    //                        dispatch(addSelectedFilter({
    //                            name: title.dbName, 
    //                            value: value
    //                        }))
    //                    }
    //                }
    //                return (
    //                    <Stack direction="row" spacing={1}
    //                        key={`${title.dbName}_${value}_${index}`}
    //                        sx={{
    //                            ml: 3, 
    //                            
    //                        }}
    //                        onClick={onClick}
    //                    >
    //                        {valueIsUsed ? 
    //                            <CheckBoxIcon sx={{color: 'primary.light'}} />
    //                            :
    //                            <CheckBoxOutlineBlankIcon sx={{color: 'customGray.dark'}} />
    //                        }
    //                        <Typography 
    //                            sx={{
    //                                color: valueIsUsed ? 
    //                                    'primary.light' : 
    //                                    'customGray.dark', 
    //                                    fontWeight: valueIsUsed ? '600': '400',
    //                                    fontSize: 18
    //                                }}
    //                        >
    //                                {value}
    //                        </Typography>                
    //                    </Stack>
    //                )
    //            })}
    //            </Box>}
    //        </Box>
    //    )
    //}

    //const handleCategoryDropDown = (title) => {
    //    const updated_list = filtersCategories.map((category) => {
    //       if (category.name === title) {
    //            category.opened = !category.opened
    //        }
    //        return category
    //    })
    //    setFiltersCategories(updated_list)
    //}

    //const DetailsItem = (props) => {
    //    const { title, valueList } = props
    //    return (
    //        <Box sx={{'&:hover': {cursor: 'pointer'}, p: 1}}>
    //            <Stack 
    //                direction="row" 
    //                alignItems="center"
    //                onClick={() => handleCategoryDropDown(title.name)}
    //            >
    //                <Typography color="primary">
    //                    {title.name}
    //                </Typography>
    //                {!title.opened ? 
    //                    <ArrowRightIcon color="primary" /> :
    //                    <ArrowDropDownIcon color="primary" />
    //                    }
    //            </Stack>
    //            {title.opened && 
    //            <Box>
    //                {valueList && valueList.length > 0 &&
    //                    <Box sx={{ml: 5}}>
    //                        {valueList.map((item, index) => {
    //                            const options = filtersList.filter(f => f.name === item.dbName).length > 0 ?
    //                            filtersList.filter(f => f.name === item.dbName)[0].values : []
    //                            return (
    //                                <DetailValueItem 
    //                                    key={item.dbName} 
    //                                    title={item} 
    //                                    valueList={options} 
    //                                    parent={title.name}
    //                                />
    //                            )
    //                            }
    //                        )}
    //                    </Box>
    //                }
    //            </Box>}
    //        </Box>
    //    )
    //}

    //const filterCategories = () => {
    //    return (
    //        <Box sx={{mt: 3, mx: 1}}>
    //            <Box
    //                sx={{
    //                    p: 1,
    //                    display: 'flex',
    //                   flexDirection: 'row',
    //                    alignItems: 'center',
    //                    justifyContent: 'space-between',
    //                    backgroundColor: 'primary.main'
    //                }}
    //            >
    //                <Typography sx={{color: 'customWhite.main', fontSize: 20, m: 1}}>
    //                    Фильтры
    //                </Typography>
    //                <Stack direction="row" spacing={1}>
    //                    <Box sx={{
    //                        border: 1, 
    //                        borderColor: 'customWhite.main', 
    //                        p: 1,
    //                        borderRadius: 1,
    //                        width: 100,
    //                        display: 'flex',
    //                        justifyContent: 'center',
    //                        '&:hover': {cursor: 'pointer'}
    //                    }}
    //                    onClick={filterApplyHandler}
    //                    >
    //                        <Typography sx={{color: 'customWhite.main', fontSize: 14,}}>
    //                            ПОИСК
    //                        </Typography>
    //                    </Box>
    //                    <IconButton onClick={handleSelectedFiltersReset}>
    //                        <ReplayIcon color="customWhite" />
    //                    </IconButton>
    //                </Stack>
    //            </Box>
    //                {filtersCategories.map((category,index) => {
    //                    return (
    //                        <Box 
    //                            key={category.name}
    //                            sx={{
    //                                borderBottom: 1, 
    //                                borderColor: 'primary.main',
    //                                p: 1
    //                            }}
    //                        >
    //                            <DetailsItem 
    //                                key={category.name} 
    //                                title={category} 
    //                                valueList={category.values} 
    //                               style={{
    //                                    borderTopWidth: index === 0 ? 0 : 1,
    //                                    borderBottomWidth: index + 1 === filtersCategories.length ? 1 : 0
    //                                }}
    //                            />
    //                        </Box>
    //                    )
    //                })}
    //            </Box>
    //    )
    //}

    const handleSearchDialogClose = () => {
        setSearchDialogVisible(false)
    }
    
    return (
        <div>
            <IconButton
                onClick={() => setSearchDialogVisible(true)}
            >
                <SearchIcon 
                    color={color ? color : 'primary'} 
                    fontSize={size ? size : 'medium'}
                />
            </IconButton>
            <CustomDialog
                maxWidth="md"
                open={searchDialogVisible}
                onClose={handleSearchDialogClose}
                dialogTitle={{
                    title: "Поиск Товара", 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'customGray.dark'}}}
            >
                <Box sx={{display: 'flex', justifyContent: 'center', m: 1}}>
                    <FormControl 
                        sx={{ m: 1, width: {xs: 300, md: 700}}} 
                        variant="outlined"
                    >
                        <InputLabel color="secondary">Поиск по названию / артикулу</InputLabel>
                        <OutlinedInput
                            color="secondary"
                            value={nameFilter}
                            onChange={nameFilterInputHandle}
                            startAdornment={<InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>}
                            label="Поиск по ключевому слову"
                        />
                    </FormControl>
                    <Button 
                        variant="contained" 
                        color='primary'
                        sx={{width: 150, height: 55, mt: 1}}
                        onClick={nameSearchHandler}
                    >
                        Поиск
                    </Button>
                </Box>
            </CustomDialog>
        </div>
    )
}

export default SearchButton;