import React from 'react';
import { useSelector } from 'react-redux';
import CustomDrawer from '../components/common/CustomDrawer';
import LoginForm from '../components/forms/loginForm';
import CartPageContent from '../components/pages/CartPage';

const CartPage = (props) => {
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)

    //const navigate = useNavigate();
    
    return (
        <div>
            <CustomDrawer>
                {!isAuthenticated && <LoginForm />}
                {isAuthenticated && <CartPageContent />}
            </CustomDrawer>
        </div>
    )
}

export default CartPage;