import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    wishList: []
}

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    setWishList: (state, action) => {
        state.wishList = [...action.payload]
    },
    wishlistReset: () => {
      return {...initialState}
    }
  }
})

export const { setWishList, wishlistReset } = wishlistSlice.actions

export default wishlistSlice.reducer