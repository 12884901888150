import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

const CustomDialog = (props) => {
    const { open, onClose } = props
    const { dialogTitle, dialogBody } = props
    const { actionButtons } = props
    const { maxWidth } = props
    const defaultMaxWidth = "sm"

    const ACTION_BUTTONS = actionButtons && actionButtons.length > 0 ? 
        actionButtons : 
        []

    const handleDialogClose = () => {
        onClose()
    }
    return (
        <Dialog 
            open={open} 
            onClose={handleDialogClose} 
            scroll='paper' 
            maxWidth={maxWidth ? maxWidth : defaultMaxWidth} 
            fullWidth={true}
        >
        <DialogTitle sx={{...dialogTitle.sx}}>
            {dialogTitle.title}
            <IconButton
                onClick={handleDialogClose}
                sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{...dialogBody.sx}}>
                    {dialogBody.text}
                </DialogContentText>
                {props.children}
            </DialogContent>
        <DialogActions>
            {ACTION_BUTTONS.map((btn) => {
                return (
                    <Button key={btn.title}
                        disabled={Object.keys(btn).indexOf('disabled') !== -1 ? 
                            btn.disabled : 
                            false} 
                        onClick={btn.onClick}
                        sx={{...btn.sx}}
                    >
                    {btn.title}
            </Button>
                )
            })}
        </DialogActions>
    </Dialog>
    )
}

export default CustomDialog;