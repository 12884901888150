import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Settings from '../../env/settings';
import urls from '../../_network/urls';
import request from '../../_network/request';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { setCartData } from "../../features/cart/cartSlice";
import { setOrdersData } from '../../features/orders/ordersSlice';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useWindowDimensions from '../common/WindowDimensions';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ButtonGroup from '@mui/material/ButtonGroup';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import CustomDialog from '../common/CustomDialog';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Grid from '@mui/material/Grid';
import base64 from "base-64"
import utf8 from "utf8"

const CartPageContent = (props) => {
    const { width } = useWindowDimensions();
    const login = useSelector(state => state.profile.login)
    const password = useSelector(state => state.profile.password)
    const cart = useSelector(state => state.cart.products)
    const defaultDeliveryMethod = useSelector(state => state.cart.deliveryMethod);
    const defaultPaymentMethod = useSelector(state => state.cart.paymentMethod);
    const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(defaultDeliveryMethod);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(defaultPaymentMethod);
    const [cartFilter, setCartFilter] = useState("");
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [cartTotal, setCartTotal] = useState(0);
    const [cartDiscountTotal, setCartDiscountTotal] = useState(0);
    const [filteredCart, setFilteredCart] = useState(cart);
    const [orderDialogVisible, setOrderDialogVisible] = useState(false);
    const [orderCreated, setOrderCreated] = useState(false);

    const theme = useTheme();
    const dispatch = useDispatch();

    const handleBackdropOpen = () => {
        setBackdropOpen(true);
    };

    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    const handleOrderDialogClose = () => {
        setOrderDialogVisible(false)
    }

    const handleOrderSuccessDialogClose = () => {
        setOrderCreated(false)
    }

    const calculateSize = () => {
        if (width < 800) {
            return 400
        } else if (width < 1800) {
            return 500
        } else {
            return 800
        }
    }

    const clearCart = () => {
        handleBackdropOpen();
        cart.forEach(element => {
            for (let i=0; i<element.quantity; i++) {
                removeFromCartHandler(element)
            }
        });
        handleBackdropClose();
    }

    const clearCartItem = (item) => {
        handleBackdropOpen();
        for (let i=0; i<item.quantity; i++) {
            removeFromCartHandler(item)
        }
        handleBackdropClose();
    }

    const addToCartHandler = async (item) => {
        await request(
            Settings.myDomain + Settings.API + urls.cart_add,
            "GET",
            {   
                itemid: item.id, 
                calculatediscounts: !item.quantity
            },
            undefined,
            {login: login, password: password}
            )
        const cart_data = await request(
            Settings.myDomain + Settings.API + urls.cart_get_list,
            "GET",
            {},
            undefined,
            {login: login, password: password}
        )
        
        dispatch(setCartData(cart_data))
    }

    const removeFromCartHandler = async (item) => {
        await request(
            Settings.myDomain + Settings.API + urls.cart_delete,
            "GET",
            {   
                itemid: item.id, 
                calculatediscounts: !item.quantity
            },
            undefined,
            {login: login, password: password}
            )
        const cart_data = await request(
            Settings.myDomain + Settings.API + urls.cart_get_list,
            "GET",
            {},
            undefined,
            {login: login, password: password}
        )
        
        dispatch(setCartData(cart_data))
    }
    
    const getImageLink = (product_image) => {
        return `${Settings.dbAddress}${urls.product_image}${product_image}`
    }

    const checkoutHandler = async () => {
        setOrderDialogVisible(false)
        handleBackdropOpen()
        try {
            const url = Settings.myDomain + Settings.API + urls.order_add
            const deliveryMethodEncoded = base64.encode(
                utf8.encode(selectedDeliveryMethod)
            )
            const paymentMethodEncoded = base64.encode(
                utf8.encode(selectedPaymentMethod)
            )
    
            await request(
                url, 
                "GET", 
                { deliverymethod: deliveryMethodEncoded, 
                    paymentmethod: paymentMethodEncoded
                },
                undefined,
                {login: login, password: password}
                )
            
            const cart_data = await request(
                Settings.myDomain + Settings.API + urls.cart_get_list,
                "GET",
                {},
                undefined,
                {login: login, password: password}
            )
            const orders_list = await request(
                Settings.myDomain + Settings.API + urls.order_get_list, 
                "GET",
                {},
                undefined,
                {login: login, password: password}
            )
            dispatch(setOrdersData(orders_list))
            dispatch(setCartData(cart_data))
            handleBackdropClose()
            setOrderCreated(true)
        } catch {
            handleBackdropClose()
            setOrderCreated(false)
        }
    }

    useEffect(() => {
        let cart_total = 0.0
        let cart_discount = 0.0

        cart.forEach(product => {
            let total_str = product.total.slice(0,-2)
            total_str = total_str.replace(",",".")
            total_str = total_str.replace(/\s/g, '');
            const total = parseFloat(total_str)
            cart_total = cart_total + total

            let discount_str = product.discount.slice(0,-2)
            discount_str = discount_str.replace(",",".")
            discount_str = discount_str.replace(/\s/g, '');
            const discount = parseFloat(discount_str)
            cart_discount = cart_discount + discount
        })

        setCartTotal(cart_total)
        setCartDiscountTotal(cart_discount)
    },[cart]);

    useEffect(() => {
        if (cartFilter) {
            const filtered_list = cart.filter(
                product => 
                    product.name1.toLowerCase().includes(cartFilter.toLowerCase()) || 
                    product.name2.toLowerCase().includes(cartFilter.toLowerCase()))
            setFilteredCart(filtered_list)
        } else {
            setFilteredCart(cart)
        }
    },[cartFilter, cart]);

    return (
        <div style={{
            marginBottom: 100, 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center'
        }}>
            <Typography 
                variant="h4"
                color="secondary.light"
                sx={{alignSelf: 'flex-start', flex: 1, mb: 5}}
            >
                Корзина
            </Typography>
            {cart.length === 0 &&
                <Box sx={{
                    display: 'flex', 
                    flexDirection: 'column',
                    justifyContent: 'center', 
                    alignItems: 'center',
                    backgroundColor: 'customGray.light',
                    borderRadius: '50%',
                    height: calculateSize(),
                    width: calculateSize(),
                    mt: 5,
                    opacity: 0.6
                }}>
                    <ShoppingCartIcon 
                        color="secondary" 
                        sx={{fontSize: 128}} />
                    <Typography 
                        color="secondary" 
                        sx={{
                            fontSize: 28, 
                            fontWeight: '500'
                        }}
                    >
                        Корзина пуста
                    </Typography>
                </Box>
            }
            {cart.length > 0 && <Box
                sx={{
                    mx: 2,
                    p: 1,
                    display: 'flex',
                    flexDirection: {xs: 'column', md: 'row'},
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'customGray.light',
                    width: '90%'
                }}
            >
                <Paper elevation={0} sx={{width: {xs: '80%', md: 300}}}>
                    <TextField
                        variant="outlined"
                        label="Фильтр"
                        size="small"
                        sx={{width: {xs: '100%', md: 300}}}
                        value={cartFilter}
                        onChange={(event) => {
                        setCartFilter(event.target.value);
                        }}
                    />
                </Paper>
                <Stack direction="row" spacing={2} alignItems='center'>
                    <Typography 
                        color='primary' 
                        fontSize='large'
                    >
                        Товары в корзине:
                    </Typography>
                    <Chip label={cart.length} color="primary" sx={{mx: 1}}/>
                    <Tooltip title="Очистить Корзину">
                        <IconButton onClick={clearCart}>
                            <DeleteIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box>}
            {cart.length > 0 && <TableContainer component={Paper} sx={{ width: '90%' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Название</TableCell>
                            <TableCell align="left">Цена</TableCell>
                            <TableCell align="left">Количество</TableCell>
                            <TableCell align="left">Скидка</TableCell>
                            <TableCell align="left">Сумма</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {filteredCart.map((product) => (
                        <TableRow
                            key={product.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell align="left" component="th" scope="row">
                            <Box sx={{
                                    display: 'flex', 
                                    flexDirection: 'row', 
                                    alignItems: 'center',
                                    m: 1,
                                }}>
                                <img 
                                    height="100"
                                    src={getImageLink(product.image)}
                                    alt="Фото отсутствует"
                                    style={{marginRight: 20}}
                                />
                                <Stack direction='column' spacing={1}>
                                    <Typography>{product.name1}</Typography>
                                    <Typography>{product.name2}</Typography>
                                </Stack>
                                
                            </Box>
                        </TableCell>
                        <TableCell align="left" sx={{fontWeight: '600'}}>{product.price}</TableCell>
                        <TableCell align="center">
                            
                            <ButtonGroup size="small">
                                <Button onClick={() => removeFromCartHandler(product)}>
                                    <RemoveIcon color="primary" />
                                </Button>
                                    <Typography
                                        sx={{ 
                                            alignSelf: 'center', 
                                            borderLeft: 0.8,
                                            borderTop: 0.8,
                                            borderBottom: 0.8,
                                            px: 3,
                                            py: 1, 
                                            borderColor: theme.palette.primary.main,
                                            color: 'primary.main'
                                        }} 
                                    >
                                        {product.quantity}
                                    </Typography>
                                <Button onClick={() => addToCartHandler(product)}>
                                    <AddIcon color="primary" />
                                </Button>
                            </ButtonGroup>
                        </TableCell>
                        <TableCell align="left">{product.discount}</TableCell>
                        <TableCell align="left" sx={{fontWeight: '600'}}>{product.total}</TableCell>
                        <TableCell align="center">
                            <IconButton
                                color="customGray"
                                onClick={() => clearCartItem(product)}
                            >
                                <ClearIcon />
                            </IconButton>
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>}
            {cart.length > 0 && <Box
                sx={{
                    mx: 2,
                    p: 1,
                    display: 'flex',
                    flexDirection: {xs: 'column', md: 'row'},
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'customGray.light',
                    width: '90%'
                }}
            >
                <Stack direction="column" spacing={1}>
                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'row', 
                        alignItems: 'center', 
                        justifyContent: 'space-between'
                    }}>
                        <Typography 
                            fontSize='large'
                            sx={{color: 'customGray.dark', m: 1}}
                        >
                            Cкидка:
                        </Typography>
                        <Stack direction="row" spacing={1}>
                            <Typography 
                                sx={{color: 'secondary.main', fontWeight: '500'}}
                                fontSize='large'
                            >
                                {cartDiscountTotal.toFixed(2)}
                            </Typography>
                            <CurrencyRubleIcon color='secondary' />
                        </Stack>
                    </Box>
                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'row', 
                        alignItems: 'center', 
                        justifyContent: 'space-between'
                    }}>
                        <Typography 
                            fontSize='large'
                            sx={{color: 'customGray.dark', fontWeight: '600', m: 1}}
                        >
                            Итого:
                        </Typography>
                        <Stack direction="row" spacing={1}>
                            <Typography 
                                sx={{color: 'primary.main', fontWeight: '600'}}
                                fontSize='large'
                            >
                                {cartTotal.toFixed(2)}
                            </Typography>
                            <CurrencyRubleIcon color='primary' />
                        </Stack>
                    </Box>
                </Stack>
                <Button 
                    variant='contained' 
                    color='primary' 
                    size='large' 
                    sx={{m: 2}}
                    onClick={() => setOrderDialogVisible(true)}
                >
                    Оформить Заказ
                </Button>
            </Box>}
            <CustomDialog
                maxWidth="md"
                open={orderDialogVisible}
                onClose={handleOrderDialogClose}
                dialogTitle={{
                    title: "Детали Заказа", 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'customGray.dark'}}}
                actionButtons={[{
                    title: "Оформить Заказ", 
                    onClick: checkoutHandler, 
                    sx: {color: 'primary.main'}}]}
            >
                <Box sx={{backgroundColor: 'primary.light', p: 1}}>
                    <Typography color='customWhite.main'>
                        Товары в заказе
                    </Typography>
                </Box>
                <Box sx={{p: 1}}>
                    {cart.map(product => {
                        return (
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={2}>
                                    <Typography color='customGray'>
                                        {product.name1}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography color='customGray'>
                                        {product.name2}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Typography color='customGray'>
                                        ({product.quantity} шт.)
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Typography 
                                        color='customGray' 
                                        fontWeight='bold' 
                                        sx={{textAlign: 'end'}}
                                    >
                                        {product.total}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    })}
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Typography color='primary' fontWeight='bold'>
                            Итого:
                        </Typography>
                        <Stack direction="row">
                            <Typography color='primary' fontWeight='bold'>
                                {cartTotal.toFixed(2)}
                            </Typography>
                            <CurrencyRubleIcon color='primary' fontSize="small" />
                        </Stack>
                        
                    </Box>
                </Box>
                <Box sx={{backgroundColor: 'customGray.light', p: 1, mt: 2}}>
                    <Typography color='primary.main'>
                        Доставка
                    </Typography>
                </Box>
                {Settings.deliveryMethods.map((method) => 
                    <Box 
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            p:1,
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}
                        key={method.dbName} 
                        onClick={() => setSelectedDeliveryMethod(method.dbName)}
                    >
                        {selectedDeliveryMethod === method.dbName ? 
                            <RadioButtonCheckedIcon color="primary" /> : 
                            <RadioButtonUncheckedIcon color="primary" />
                        }
                        
                        <Typography 
                            color="primary"
                        >
                            {method.uiName}
                        </Typography>
                    </Box>
                )}
                <Box sx={{backgroundColor: 'customGray.light', p: 1, mt: 2}}>
                    <Typography color='primary.main'>
                        Оплата
                    </Typography>
                </Box>
                {Settings.paymentMethods.map((method) => 
                    <Box 
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            p:1,
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}
                        key={method.dbName} 
                        onClick={() => setSelectedPaymentMethod(method.dbName)}
                    >
                        {selectedPaymentMethod === method.dbName ? 
                            <RadioButtonCheckedIcon color="primary" /> : 
                            <RadioButtonUncheckedIcon color="primary" />
                        }
                        
                        <Typography 
                            color="primary"
                        >
                            {method.uiName}
                        </Typography>
                    </Box>
                )}
            </CustomDialog>
            <CustomDialog
                open={orderCreated}
                onClose={handleOrderSuccessDialogClose}
                dialogTitle={{
                    title: "Поздравляем!", 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "Вы успешно оформили заказ. Новый заказ доступен в списке заказов.", 
                    sx: {color: 'customGray.dark'}}}
                actionButtons={[{
                    title: "Ок", 
                    onClick: handleOrderSuccessDialogClose, 
                    sx: {color: 'primary.main'}}]}
            />
            <Backdrop
                sx={{ 
                    color: '#fff', 
                    zIndex: (theme) => theme.zIndex.drawer + 1 
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default CartPageContent;
