import React from 'react';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useWindowDimensions from '../common/WindowDimensions';

const CategoryListItem = (props) => {
    const { height } = useWindowDimensions();
    const { item } = props

    const navigate = useNavigate();

    const getNavLink = (id,is_folder) => {
        if (is_folder) {
            return `/catalog`
        } else {
            return `/products`
        }
    }
    
    return (
        <Box
            key={item.id}
            onClick={() => navigate(getNavLink(item.id,item.isFolder), {state: {parent_id: item.id}})}
            sx={{
                width: '100%',
                p: 1,
                pr: 10,
                textDecoration: "none",
                borderBottom: 1,
                borderColor: 'primary.main',
                '&:hover': {
                    borderColor: 'secondary.main',
                    opacity: [0.9, 0.8, 0.7],
                    cursor: 'pointer'
                },
            }}
        >
            <Typography
                color="primary"
                fontSize={height > 1000 ? 20 : 16}
            >
                {item.name}
            </Typography>
        </Box>            
    )
}

export default CategoryListItem;