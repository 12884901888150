import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import profileReducer from '../app/features/profile/profileSlice';
import cartReducer from '../app/features/cart/cartSlice';
import catalogReducer from '../app/features/catalog/catalogSlice';
import wishlistReducer from '../app/features/wishlist/wishlistSlice';
import ordersReducer from '../app/features/orders/ordersSlice';
import filtersReducer from '../app/features/filters/filtersSlice';
import drawerReducer from '../app/features/webapp/drawerSlice';

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({ 
  drawer: drawerReducer,
  profile: profileReducer,
  cart: cartReducer,
  catalog: catalogReducer,
  wishlist: wishlistReducer,
  orders: ordersReducer,
  filters: filtersReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
})

export const persistor = persistStore(store)